<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                relastats {{perf}}
                <DataTable :value="relastats" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">               
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="rdate" header="日付" filterField="rdate" filterMatchMode="contains" ref="rdate" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                      </template>
                    </Column>                    
                    <Column field="rtime" header="時刻" :sortable="true"></Column>
                    <Column field="rtype" header="関係タイプ" filterField="rtype" filterMatchMode="contains" ref="rdate" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                      </template>
                    </Column>
                    <Column field="rcd" header="関係コード" filterField="rcd" filterMatchMode="contains" ref="rcd" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                      </template>
                    </Column>                    
                    <Column field="rname" header="関係名" filterField="rname" filterMatchMode="contains" ref="rname" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                      </template>
                    </Column>
                    <Column field="1d_count" header="銘柄数" :sortable="true"></Column>
                    <Column field="1d_mean" header="1d_mean" :sortable="true">
                      <template #body="slotProps">
                        <div :class="v1dmean_priceClass(slotProps.data)">
                          {{slotProps.data["1d_mean"]}}
                        </div>
                      </template>
                    </Column>
                    <Column field="2d_mean" header="2d_mean" :sortable="true">
                      <template #body="slotProps">
                        <div :class="v2dmean_priceClass(slotProps.data)">
                          {{slotProps.data["2d_mean"]}}
                        </div>
                      </template>
                    </Column>           
                    <Column field="3d_mean" header="3d_mean" :sortable="true">
                      <template #body="slotProps">
                        <div :class="v3dmean_priceClass(slotProps.data)">
                          {{slotProps.data["3d_mean"]}}
                        </div>
                      </template>
                    </Column>    
                    <Column field="5d_mean" header="5d_mean" :sortable="true">
                      <template #body="slotProps">
                        <div :class="v5dmean_priceClass(slotProps.data)">
                          {{slotProps.data["5d_mean"]}}
                        </div>
                      </template>
                    </Column>                         
                    <Column field="1d_std" header="1d_std" :sortable="true"></Column>
                    <Column field="1d_volume_mean" header="1d_volume_mean" :sortable="true"></Column>
                    <Column field="1d_volume" header="1d_volume" :sortable="true"></Column>
                    <Column field="2d_std" header="2d_std" :sortable="true"></Column>
                    <Column field="2d_volume_mean" header="2d_volume_mean" :sortable="true"></Column>
                    <Column field="3d_std" header="3d_std" :sortable="true"></Column>
                    <Column field="3d_volume_mean" header="3d_volume_mean" :sortable="true"></Column>
                    <Column field="5d_std" header="5d_std" :sortable="true"></Column>
                    <Column field="5d_volume_mean" header="5d_volume_mean" :sortable="true"></Column>
                    <Column field="1d_max" header="その他(1d_max)" :sortable="true"></Column>
                    <Column field="1d_min" header="その他(1d_min)" :sortable="true"></Column>
                    <Column field="1d_median" header="その他(1d_median)" :sortable="true"></Column>
                    <Column field="1d_quantile25" header="その他(1D_quantile25)" :sortable="true"></Column>
                    <Column field="1d_quantile75" header="その他(1D_quantile75)" :sortable="true"></Column>
                    <Column field="sdate" header="その他(sdate)" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import RelastatsService from '@/service/RelastatsService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            relastats: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'rdate': {value: '', matchMode: 'contains'},
                'rtype': {value: '', matchMode: 'contains'},
                'rcd': {value: '', matchMode: 'contains'},
                'rname': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    relastatsService: null,
    created() {
        this.relastatsService = new RelastatsService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "id",
            sortOrder: "-1",
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.relastatsService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.relastats = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        },
        v1dmean_priceClass(data) {
          return [
            {
              'positive': data["1d_mean"] > 0,
              'negative': data["1d_mean"] < 0,
              'neutral': data["1d_mean"] == 0,
            }
          ];
        },
        v2dmean_priceClass(data) {
          return [
            {
              'positive': data["2d_mean"] > 0,
              'negative': data["2d_mean"] < 0,
              'neutral': data["2d_mean"] == 0,
            }
          ];
        },
        v3dmean_priceClass(data) {
          return [
            {
              'positive': data["3d_mean"] > 0,
              'negative': data["3d_mean"] < 0,
              'neutral': data["3d_mean"] == 0,
            }
          ];
        },
        v5dmean_priceClass(data) {
          return [
            {
              'positive': data["5d_mean"] > 0,
              'negative': data["5d_mean"] < 0,
              'neutral': data["5d_mean"] == 0,
            }
          ];
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';
</style>